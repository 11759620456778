import { defineStore } from 'pinia'

export const usePreferencesStore = defineStore('preferences', {
  state: function() {
    return {
      monitoring: {
        importance: false,
      },
      patient_search: '',
      tag_search: '',
      company_search: '',
      monitoring_search: '',
      appuser_search: '',
      companies: null,
      barcoding: {
        default: null,
        preference: null
      },
      timezone: {
        useBrowserTime: false
      },
      patientsTableView: false,
      appusersTableView: false,
      tagsTableView: false,
      monitoringTableView: false,
      absoluteScheduleTableView: false,
      companiesTableView: false,
    };
  },
  actions: {
    updatePatientSearch(payload) {
      this.patient_search = payload;
    },
    updatePatientsTableView(payload) {
      this.patientsTableView = payload;
    },
    updateAppUsersTableView(payload) {
      this.appusersTableView = payload;
    },
    updateTagsTableView(payload) {
      this.tagsTableView = payload;
    },
    updateMonitoringTableView(payload) {
      this.monitoringTableView = payload;
    },
    updateAbsoluteScheduleTableView(payload) {
      this.absoluteScheduleTableView = payload;
    },
    updateMonitoringSearch(payload) {
      this.monitoring_search = payload;
    },
    updateAppUsersSearch(payload) {
      this.appuser_search = payload;
    },
    updateTagFilters(payload) {
      this.tag_search = payload;
    },
    updateCompanyFilters(payload) {
      this.companies = payload;
    },
    updateCompanySearch(payload) {
      this.company_search = payload;
    },
    updateCompaniesTableView(payload) {
      this.companiesTableView = payload;
    },
    updateDefaultCamera(payload) {
      this.barcoding.default = payload;
    },
    updatePreference(payload) {
      this.barcoding.preference = payload;
    },
    updateTimezone(payload) {
      if (this.timezone == undefined) {
        this.timezone = {
          useBrowserTime: false
        };
      }
      this.timezone.useBrowserTime = payload;
      if (payload === false) {
        delete window.axios.defaults.headers.common['X-TIMEZONE-OFFSET-MINUTES'];
        return;
      }
      const date = new Date();
      window.axios.defaults.headers.common['X-TIMEZONE-OFFSET-MINUTES'] = date.getTimezoneOffset();
    },
    resetPreferences() {
      const endpoints = ['monitoring', 'patients', 'tags', 'companies', 'barcoding', 'timezone'];
      for (const endpoint of endpoints) {
        if (this[endpoint] != null) {
          for (const [key, value] of Object.entries(this[endpoint])) {
            if (this[endpoint][key] != null) {
              this[endpoint][key] = null;
            }
          }
        }
      }
    },
  },
});
