import { useAuthStore } from "./authentication";
import { useAppearanceStore } from "./appearance";
import { useErrorsStore } from "./errors";
import { useSuccessStore } from "./success";
import { usePreferencesStore } from "./preferences";
import { useCompaniesStore } from "./companies";
import { useVersioningStore } from "./versioning";

import { defineStore } from 'pinia'

export const useRootStore = defineStore('root', {
  actions: {
    boot() {
      if (localStorage.getItem('store.preferences')) {
        const preferences = usePreferencesStore();
        preferences.$store = { ...JSON.parse(localStorage.getItem('store.preferences')) };
      }

      if (localStorage.getItem('store.appearance')) {
        const appearance = useAppearanceStore();
        appearance.$store = { ...JSON.parse(localStorage.getItem('store.appearance')) };
      }

      const authentication = useAuthStore();
      authentication.loadLocal();
      authentication.jobs.checking = false;
      authentication.jobs.check = null;

      authentication.validateAuth();
      const success = useSuccessStore();
      success.closeMessage();
      const errors = useErrorsStore();
      errors.closeError();
      const companies = useCompaniesStore();
      companies.loadCompanies();
      const versioning = useVersioningStore();
      versioning.checkInternalVersions();
      versioning.bootVersion();
      const preferences = usePreferencesStore();
      preferences.updateTimezone(preferences.timezone == null ? false : preferences.timezone.useBrowserTime);
    }
  }
});
