<template>
  <PVButton icon="material-icons menu" @click="togglemenu($event)"/>
  <PVMenu :model="items" popup ref="menu">
    <template #item="{ item, props, hasSubmenu }">
      <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
        <a :href="href" v-bind="props.action" @click="navigate">
          <span :class="item.icon" />
          <span class="ml-2">{{ item.label }}</span>
        </a>
      </router-link>
      <div v-else-if="item.toggleDM">
        {{ item.label }}
        <PVInputSwitch :value="dark" @change="appearanceStore.toggleDarkMode(!appearanceStore.isDark)"/>
      </div>
      <div v-else-if="item.toggleBT">
        {{ item.label }}
        <PVInputSwitch :value="timezone" @change="preferencesStore.updateTimezone(!preferencesStore.timezone.useBrowserTime)"/>
      </div>
      <a v-else :href="item.url" :target="item.target" v-bind="props.action">
        <span :class="item.icon" />
        <span class="ml-2">{{ item.label }}</span>
        <span v-if="hasSubmenu" class="pi pi-fw pi-angle-down ml-2" />
      </a>
    </template>
  </PVMenu>
  <SuperPermissionsToggleDialog v-model="permDialog" class="text-center mt-4"/>
</template>

<script>

import SuperPermissionsToggleDialog from "./SuperPermissionsToggleDialog.vue";
import _ from 'lodash';
import { useAppearanceStore } from "../../../../store/appearance";
import { usePreferencesStore } from "../../../../store/preferences";
import { useAuthStore } from "../../../../store/authentication";
import PVDivider from 'primevue/divider';
import PVInputSwitch from 'primevue/inputswitch';
import PVMenubar from 'primevue/menubar';
import PVMenu from 'primevue/menu';

export default {
  name: "NavigationDrawer",
  components: { SuperPermissionsToggleDialog, PVDivider, PVInputSwitch, PVMenubar, PVMenu },
  setup() {
    const appearanceStore = useAppearanceStore();
    const authStore = useAuthStore();
    const preferencesStore = usePreferencesStore();
    return { appearanceStore, authStore, preferencesStore };
  },
  data() {
    return {
      options: null,
      firstLoad: true,
      permDialog: false,      
    }
  },
  computed: {
    timezone() {
      return this.preferencesStore.timezone != null ? this.preferencesStore.timezone.useBrowserTime : null;
    },
    dark() {
      return this.appearanceStore.isDark;
    },
    superAdmin() {
      return this.authStore.access.superAdmin;
    },
    permissions() {
      return this.authStore.access.permissions;
    },
    
    items() {
      let items = [];
      if (!this.options) return [];
      this.options.forEach((option) => {
        items.push({ label:option.title, icon:('material-icons ' + option.icon), route: option.route, command:()=>{ this.$router.push(option.route); } });
      });

      items.push({ label:'' });
      items.push({ label:'Settings',
        icon: 'pi pi-cog',
        items:[
          { label:'Dark Mode', toggleDM:true },
          { label:'Browser Timezone', toggleBT:true },
          { label:'Change Permissions', command:()=>{ this.permDialog = true; } },
        ] });
      items.push({ label:'Privacy Policy', url:'/privacy_policy' });
      items.push({ label:'Terms & Conditions', url:'/terms_conditions' });

      return items;
      
/*      return [
        {
          label: '...',
          icon: 'material-icons menu',
          items: items,
        },
      ];*/
    }
  },
  beforeMount() {
    this.storeUpdate();
    this.authStore.$subscribe((mutation,state) => {
      this.storeUpdate();
    });
  },
  methods: {
    togglemenu(e) {
      this.$refs.menu.toggle(e);
    },

    unauthorisedOptions() {
      return [
        {
          icon: 'email',
          title: 'Login',
          route: '/login',
        }
      ];
    },
    defaultOptions() {
      return [
        {
          icon: 'dashboard',
          title: 'Home',
          route: '/'
        },
        {
          icon: 'person',
          title: 'User',
          route: '/user'
        }
      ];
    },
    storeUpdate: _.debounce(function () {
      this.checkOptions();
    }, 200),
    checkOptions() {
      if (this.authStore != null && this.authStore.isLoggedIn && this.permissions != null) {
        let options = this.defaultOptions();
        // Now we need to add checks for each permission
        if (this.permissions.patients.view) {
          options = options.concat([
            {
              icon: 'people',
              title: 'Patients',
              route: '/patients',
              exact: false
            }
          ]);
        }
        if (this.permissions.tags.view) {
          options = options.concat([
            {
              icon: 'devices',
              title: 'Tags',
              route: '/tags',
              exact: false
            }
          ]);
        }
        if (this.permissions.companies.view) {
          options = options.concat([
            {
              icon: 'business',
              title: 'Companies',
              route: '/companies',
              exact: false
            },
          ]);
        }
        if (this.permissions.monitoring.view) {
          options = options.concat([
            {
              icon: 'view_compact',
              title: 'Monitoring',
              route: '/monitoring'
            },
            {
              icon: 'view_compact',
              title: 'Monitoring (Beta)',
              route: '/monitoring2'
            }
          ]);
        }
        if (this.authStore.access.superAdmin) {
          options = options.concat([
            {
              icon: 'new_releases',
              title: 'Test Features',
              route: '/testing',
              exact: false
            },
            {
              icon: 'smartphone',
              title: 'App Users',
              route: '/app/users',
              exact: false
            },
            {
              icon: 'auto_stories',
              title: 'Automated Reports',
              route: '/automated-reports',
              exact: false
            }
          ]);
        }
        this.options = options;
      } else {
        this.options = this.unauthorisedOptions();
      }
      this.firstLoad = false;
    }
  },
}
</script>

<style scoped>

</style>
