import superCheck from "../guards/superCheck";

export let testingRoutes = [
  /*
  {
    path: '/testing',
    name: 'Testing Page',
    component: () => import('../../views/testing/Testing.vue'),
    beforeEnter: superCheck
  },*/
  {
    path: '/automated-reports',
    name: 'Automated Reports',
    component: () => import('../../views/reporting/Automated.vue'),
    beforeEnter: superCheck
  },
];
