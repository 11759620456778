import { defineStore } from 'pinia'

export const useSuccessStore = defineStore('success', {
  state: function() {
    return {
      messages: null,
      forceShow: false
    };
  },
  actions: {
    setMessage(message) {
      if (this.messages === null) {
        this.messages = !Array.isArray(message) ? [message] : message;
        return;
      }
      if (Array.isArray(this.messages)) {
        Array.isArray(message) ? this.messages.concat(message) : this.messages.push(message);
        return;
      }
      this.messages = message;
    },
    closeMessage() {
      this.messages = null;
      this.forceShow = false;
    },
    saveSuccessful() {
      this.setMessage('Saved Successfully');
    },
    remove(e) {
      let i = this.messages.indexOf(e);
      console.log("FIND ", i);
    },
  },
});
