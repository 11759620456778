import { useAuthStore } from '../../store/authentication.js';

export default (to, from, next) => {
  const authentication = useAuthStore();
  if (!authentication.isLoggedIn) {
    next('/login');
  } else {
    next();
  }
}
