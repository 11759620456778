<template>
  <div class="flex flex-row text-center" style="min-width: 200px">
    <div>
      <slot></slot>
    </div>
    <div class="text-left" style="min-width: 120px">
      <div v-tooltip.bottom="hint" style="margin-top: 12px;">
        {{ label }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactConfigSwitchComponent",
  props: {
    hint: String,
    label: String
  },
  emits:['open','input'],
  data() {
    return {
      val: false
    }
  },
  methods: {
    emit(value) {
      if (value == null) {
        value = false;
      }
      this.$emit('input', value);
    }
  },
}
</script>

<style scoped>

</style>
