import { router } from '../router/index';
import _ from 'lodash';
import { defineStore } from 'pinia'
import { usePreferencesStore } from "./preferences";
import { useCompaniesStore } from "./companies";

export const useAuthStore = defineStore('auth', {
  state: function() {
    return {
      isLoggedIn: false,
      access: {
        tokenType: null,
        accessToken: null,
        superAdmin: false,
        superAdminView: false,
        superStatus: false,
        user: null,
        permissionsCache: null,
        permissions: null,
      },
      preferred_locale: undefined,
      jobs: {
        check: null,
        checking: false
      },
    };
  },
  actions: {
    toggleSuperAdmin(status) {
      this.access.permissions.dirty = true;
      this.access.superAdmin = status;
    },
    setAccessState(params) {
      const keys = ["superAdmin", "superAdminView", "superStatus"];
      for (let key of keys) {
        if (params.hasOwnProperty(key)) {
          this.access[key] = params[key];
        }
      }
    },
    updateAccessPermissions(permissions) {
      this.access.permissionsCache = permissions;
      if (this.access.permissions == null || !this.access.permissions.dirty) {
        this.resetPermissions();
      }
    },
    updatePreferredLocale(loc) {
      this.preferred_locale = loc;
    },
    updatePermission(change) {
      this.access.permissions.dirty = true;
      this.access.permissions[change.parent][change.permission] = change.status;
    },
    resetPermissions() {
      this.access.superAdmin = this.access.superStatus;
      if (this.access.permissionsCache != null) {
        this.access.permissions = JSON.parse(JSON.stringify(this.access.permissionsCache));
        this.access.permissions.dirty = false;
      }
    },
    loadPermissions() {
      axios.get(`/api/smart/user`).then(response => {
        this.access.user = response.data.data;
        this.isLoggedIn = true;
        this.jobs.checking = false;
        let group = this.access.user.usergroup;
        this.setAccessState({
          superStatus: group == 1,
          superAdmin: group == 1,
          superAdminView: group == 1 || group == 6,
        });
        this.updateAccessPermissions(response.data.permissions);
        this.updatePreferredLocale(response.data.preferred_locale);
        this.saveLocal();
        this.finished();
      }).catch(error => {
        if (error.response != undefined && error.response.status === 401) { // if we got a unauthorised response then we logout otherwise there is no point
          this.logout().catch(error => {
            setTimeout(() => {
              this.jobs.checking = false;
            }, 3000);
          });
        }
      });
    },
    checkAuthState: _.debounce(function () {
      if (!this.jobs.checking) {
        this.jobs.checking = true;
        this.loadPermissions();
      }
    }, 500),
    finished() {
    },
    getAccess() {
      return this.access;
    },
    validateAuth() {
      // Set the headers
      window.axios.defaults.headers.common['Authorization'] = this.access.tokenType + ' ' + this.access.accessToken;
      if (this.access.accessToken != null) {
        this.isLoggedIn = true;
        this.checkAuthState();
        if (this.jobs.check == null) {
          this.jobs.check = setInterval(() => {
            this.checkAuthState();
          }, 5 * 60 * 1000);
        }
      }
    },

    loadLocal() {
      if (localStorage.getItem('store.authentication')) {
        const a = localStorage.getItem('store.authentication');
        const a2 = JSON.parse(a);
        this.$state = a2;
        window.axios.defaults.headers.common['Authorization'] = this.access.tokenType + ' ' + this.access.accessToken;
      }
    },
    saveLocal() {
      const ss = JSON.stringify(this.$state);
      localStorage.setItem('store.authentication', ss);
    },
    
    togglePermissionsReset() { this.resetPermissions(); },
    
    login(details) {
      const context = this;
      return new Promise((resolve, reject) => {
        axios.post(`/api/smart/login`, details).catch(() => {
          reject();
        }).then(response => {
          this.access.tokenType = 'Bearer';
          this.access.accessToken = response.data.accessToken;
          this.validateAuth();
          this.saveLocal();
          router.replace('/');
          const companiesStore = useCompaniesStore();
          companiesStore.loadCompanies();
          resolve();
        });
      });
    },
    logout() {
      const context = this;
      return new Promise((resolve, reject) => {
        axios.post(`/api/smart/logout`).catch(() => {
          reject();
        }).then(() => {
          window.localStorage.clear();
          window.sessionStorage.clear();
          context.resetAuthentication();
          const preferencesStore = usePreferencesStore();
          preferencesStore.resetPreferences();
          context.validateAuth();
          router.replace('/login').catch(error => {
            // We are already on the login page
          });
          resolve();
        });
      });
    },
    resetAuthentication() {
      this.isLoggedIn = false;
      if (this.access != null) {
        for (const [key, value] of Object.entries(this.access)) {
          this.access[key] = null;
        }
      }
    }
  }
});
