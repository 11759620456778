/*
 | ------------------------------------------------------------------------------------
 |                      Not Found Catch All
 | ------------------------------------------------------------------------------------
 */
export let notFoundRoutes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'Page Not Found',
    component: () => import('../../views/other/NotFound.vue')
  },
];
