import { defineStore } from 'pinia'

export const useAppearanceStore = defineStore('appearance', {
  state: function() {
    return {
      isDark: false,
      isDrawerOpen: false,
      transitions: true
    };
  },
  actions: {
    toggleDrawer(status = false) {
      this.isDrawerOpen = status;
    },
    toggleDarkMode(status = false) {
      this.isDark = status;
    }
  }
});
