<template>
  <!--  <div v-if="permissions != null">-->
  <!--    <PVButton label="Change Permissions" raised severity="btnbg" @click="dialog = true"/>-->
  <PVDialog :visible="dialog" header="Change Permissions" @update:visible="$emit('close')" >
    <div class="flex flex-column">
      <ContactConfigSwitchComponent label="Super Admin" hint="Toggles on/off super admin features">
        <input type="checkbox" :checked="superAdmin" style="margin-top: 12px;" @change="toggleSuperAdmin"/>
      </ContactConfigSwitchComponent>
      <PVDivider/>
      <div v-for="permission in permissionsKeys" :key="permission" class="flex flex-row">
        <ContactConfigSwitchComponent :label="permission + ' view'" hint="Toggles on/off viewing">
          <input type="checkbox" :checked="permissions[permission].view" style="margin-top: 12px;" @change="togglePermissions(permission, 'view', !permissions[permission].view)"/>
        </ContactConfigSwitchComponent>
        <ContactConfigSwitchComponent :label="permission + ' create'" hint="Toggles on/off creating">
          <input type="checkbox" :checked="permissions[permission].create"
                 style="margin-top: 12px;"
                 @change="togglePermissions(permission, 'create', !permissions[permission].create)"/>
        </ContactConfigSwitchComponent>
      </div>
      <PVDivider/>
      <div class="flex flex-row flex-wrap justify-content-end mt-2">
        <PVButton label="Reset" raised severity="error" @click="authenticationStore.togglePermissionsReset()"/>
      </div>
    </div>
  </PVDialog>
<!--  </div>-->
</template>

<script>
import ContactConfigSwitchComponent from "../../../patients/patient/contacts/ContactConfigSwitchComponent.vue";
import PVDialog from 'primevue/dialog';
import { useAuthStore } from "../../../../store/authentication";
import PVDivider from 'primevue/divider';

export default {
  name: "SuperPermissionsToggleDialog",
  components: { PVDialog, PVDivider, ContactConfigSwitchComponent },
  props: {
    dialog: {
      type:Boolean,
      value:false,
    }
  },
  setup() {
    const authenticationStore = useAuthStore();
    return { authenticationStore };
  },
  data() {
    return {
      //      dialog: false,
      permissionsKeys: [
        'patients',
        'contacts',
        'monitoring',
        'tags',
        'companies',
        'events',
        'pack'
      ]
    }
  },
  computed: {
    superAdmin() {
      return this.authenticationStore.access.superAdmin;
    },
    permissions() {
      return this.authenticationStore.access.permissions;
    }
  },
  methods: {
    toggleSuperAdmin() {
      this.authStore.toggleSuperAdmin(!this.superAdmin);
    },
    togglePermissions(parent, permission, value) {
      this.authStore.updatePermission({
        parent: parent,
        permission: permission,
        status: value
      });
    }
  }
}
</script>

<style scoped>

xxinput[type=checkbox] {
    min-width: 18px;
    min-height: 18px;
}

xxinput[type=checkbox]:hover {
    cursor: pointer;
}

</style>
