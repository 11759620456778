import { defineStore } from 'pinia'

export const useVersioningStore = defineStore('versioning', {
  state: function() {
    return {
      versions: {
        current: null,
        staging: null
      },
      versionInterval: null,
      updateAvailable: false
    };
  },
  actions: {
    checkVersion() {
      if (typeof this.versions == 'string') {
        this.versions = {
          current: null,
          staging: null
        };
      }
      // We need to go and fetch the mix manifest and compare this to the current version
      axios.get('/version')
        .then(response => {
          if (this.versions.current == null) {
            this.versions.current = JSON.stringify(response.data);
          }
          // Check if our current version matches the version
          if (!this.updateAvailable && this.versions.current != JSON.stringify(response.data)) {
            this.updateAvailable = true;
          }
          // Update our staging version to the latest versions
          if (this.updateAvailable && this.versions.staging != JSON.stringify(response.data)) {
            this.versions.staging = JSON.stringify(response.data);
          }
          this.checkInternalVersions();
        }).catch((e) => {
          if (axios.isCancel(e)) {
            console.log("Cancelled ", e.message);
          }
        });
    },
    checkInternalVersions() {
      if (this.versions.staging == null || this.versions.current == this.versions.staging) {
        this.updateAvailable = false;
        this.versions.staging = null;
      }
    },
    bootVersion() {
      this.versions.current = null;
      this.versions.staging = null;
      this.updateAvailable = false;
      this.versionInterval = setInterval(() => this.checkVersion(), 300000);
    },
    updateSite() {
      location.reload();
    }
  },
});
