import { defineStore } from 'pinia'

export const useErrorsStore = defineStore('errors', {
  state: function() {
    return {
      errors: null,
      forceShow: false,
    };
  },
  actions: {
    setErrors(errors) {
      if (this.errors === null) {
        this.errors = !Array.isArray(errors) ? [errors] : errors;
        return;
      }
      if (Array.isArray(this.errors)) {
        Array.isArray(errors) ? this.errors.concat(errors) : this.errors.push(errors);
        return;
      }
      this.errors = errors;
    },
    closeError() {
      this.errors = null;
      this.forceShow = false;
    }
  }
});
